<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="flex-center-between bank-title">
      <div>银行卡充值</div>
      <div class="flex-center icon" @click="toTransfer">
        <img :src="transferUrl" alt="">
        更换
      </div>
    </div>

    <div class="bank-box flex-center">
      <div class="name flex-column-center">
        <img :src="bankUrl" alt="">
        <span>{{bankInfo.BankName}}</span>
      </div>
      <div class="flex-1">
        <div class="info">{{bankInfo.UserName}}</div>
        <div class="info">{{bankInfo.BankCard}}</div>
        <div></div>
      </div>
      <div class="copy-icon" @click="copyData">
        <img :src="copyUrl" alt="">
      </div>
    </div>

    <div class="recharge-form ">
      <div class="label">{{i18n.t('recharge.label4')}}</div>
      <div class="input">
        <input type="number" v-model="form.number" :placeholder="i18n.t('recharge.placeholder')">
      </div>
    </div>

    <div class="recharge-form ">
      <div class="label">转账截图</div>
    </div>
    <div class="img-box ">
      <input type="file" id="fileInput" class="hide-input" @change="chooseFile">
      <div class="flex-column-center img" v-if="!form.img" @click="addFile">
        <img :src="pictureUrl" alt="">
        <span>上传转账截图</span>
      </div>
      <div class="preview flex-center" v-if="form.img" @click="addFile">
        <img :src="`/upload/${form.img}`" alt="">
      </div>
    </div>

    <div class="btn mt-50">
      <Button @click="submit">立即充值</Button>
    </div>


    <Popup v-model="showPopup" class="modal-popup">
      <div class="bank-list">
        <div class="modal-title flex-center-between">
          <span>银行卡管理</span>
          <img :src="closeUrl" alt="">
        </div>

        <div class="list">
          <div class="bank flex-center" v-for="(item, i) in bankList" :key="i"  @click="chooseBank(item)">
            <div class="name flex-column-center">
              <img :src="bankUrl" alt="">
              <span>{{item.BankName}}</span>
            </div>
            <div class="flex-1">
              <div class="info">{{item.UserName}}</div>
              <div class="info">{{item.BankCard}}</div>
              <div></div>
            </div>
            <div class="icon">
              <img :src="checkUrl2" alt="" v-if="chooseBankInfo.ID == item.ID">
              <img :src="checkUrl" alt="" v-else>
            </div>
          </div>

          <div class="bank flex-center" @click="toPath('bindCard')">
            <img :src="addUrl" alt="" class="add-icon">
            <span class="desc">添加银行卡</span>
          </div>
        </div>

        <div class="btn">
          <Button class="" @click="cancel">取消</Button>
          <Button class="blue" @click="doTransfer">确定</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Popup } from 'vant'
import headerBar from '@/components/header'
import Clipboard from 'clipboard';
import { rechargeStatistics, doRecharge, userInfo,ListPayManage,getCompanyBank } from '@/api/user'
export default {
  components: {
    headerBar,
    Button,
    Popup
  },
  data() {
    return {
      form: {
        number: '',
        img: '',
      },
      showPopup: false,
      transferUrl: require('@/assets/imgs/icon-transfer.png'),
      bankUrl: require('@/assets/imgs/icon-card.png'),
      copyUrl: require('@/assets/imgs/icon-copy.png'),
      pictureUrl: require('@/assets/imgs/picture.png'),
      closeUrl: require('@/assets/imgs/icon-close.png'),
      checkUrl: require('@/assets/imgs/icon-check.png'),
      checkUrl2: require('@/assets/imgs/icon-checked.png'),
      addUrl: require('@/assets/imgs/icon-add.png'),
      bankInfo: {},
      chooseBankInfo: {},
      bankList: [
        {
          ID: 1,
          BankName: '中国银行',
          UserName: '张三',
          BankCard: '1238102381023812830'
        },
        {
          ID: 2,
          BankName: '中国银行',
          UserName: '李四',
          BankCard: '1238102381023812830'
        },
        {
          ID: 3,
          BankName: '中国银行',
          UserName: '王五',
          BankCard: '1238102381023812830'
        },
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.bankInfo = this.bankList[0]
    this.chooseBankInfo = this.bankList[0]
  },
  methods: {
    
    back() {
      this.$router.go(-1)
    },
    chooseFile(e) {

    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    submit() {

    },
    copyData() {
      let data = this.bankInfo.UserName + '   ' + this.bankInfo.BankCard
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.success('复制成功')
    },
    chooseBank(data) {
      this.chooseBankInfo = data
    },
    cancel() {
      this.showPopup = false
    },
    toTransfer() {
      this.showPopup = true
    },
    doTransfer() {
      this.bankInfo = Object.assign({}, this.chooseBankInfo)
      this.showPopup = false
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>